import React, {useState} from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bools
}

const defaultProps = {
  ...SectionProps.defaults,
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const [sent, setSent] = useState(false)
  const [text, setText] = useState("")

  const handleSend = async() => {
    setSent(true)
    alert("Your Subscripton Has Been Added!")
    try {
      await axios.post("https://roboteam-backend.herokuapp.com/send_mail", {
        text
      })
      alert("Your Subscripton Has Been Added!")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Sign Up For Our Newsletter!
              </h3>
          </div>
          <div className="cta-action">
            <Input id="newsletter" type="email" vaule="text" hasIcon="right" onChange={(e) => setText(e.target.value)} placeholder="Your best email" onClick={handleSend}>
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg" >
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
